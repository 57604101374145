/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Rome"), React.createElement("p", null, "Pour se loger, Rome s’articule en six quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/rome/rome-centre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le centre historique"), " : très touristique, avec la fontaine de Trevi, la place Navone ou le Campo de’ Fiori."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/rome/vaticano.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le Vatican"), ", à l’ouest : quartier calme du Prati, non loin de la place Saint-Pierre et du château Saint-Ange."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/rome/trastevere.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Trastevere"), ", au sud-ouest : les ruelles pavées de ce quartier abritent de nombreux cafés et restaurants."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/rome/rione-monti.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Monti"), ", au sud-est : quartier branché, près du Colisée et de Saint-Jean de Latran."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/rome/stazione-termini.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gare de Termini"), ", à l’est : quartier populaire de l’Esquilino, avec de nombreux hôtels."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/rome/spagna.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Piazza di Spagna"), ", au nord : très chic, dans le quartier des enseignes de luxe et de la Villa Borghèse."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
